import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { Input } from 'reactstrap';

import { injectIntl, intlShape } from 'react-intl';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import { roundWithPrecision } from '../../../util/number';
import Icon from '../../common/Icon';
import { DEFAULT_MAX_QUANTITY } from '../ProductAddToCart';

const createCartProduct = (id, qty) => {
  return { id, qty };
};

@observer
class ProductMatrixCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupOpen: false,
      quantity: '',
    };

    this.inputRef = createRef(null);
  }

  componentDidUpdate() {
    const { propertyListRef } = this.props;
    const { popupOpen } = this.state;

    if (propertyListRef?.current && popupOpen) {
      propertyListRef.current.addEventListener('scroll', this.hideTooltip);
    }

    if (propertyListRef?.current && !popupOpen) {
      propertyListRef.current.removeEventListener('scroll', this.hideTooltip);
    }
  }

  componentWillUnmount() {
    const { propertyListRef } = this.props;

    if (propertyListRef?.current) {
      propertyListRef.current.removeEventListener('scroll', this.hideTooltip);
    }
  }

  openPopover = () =>
    this.setState({
      popupOpen: true,
    });

  closePopover = () =>
    this.setState({
      popupOpen: false,
    });

  hideTooltip = (event) => {
    if (this.inputRef?.current && event.type === 'scroll') {
      // Let onBlur-callback handle state update.
      this.inputRef.current.blur();
    }
  };

  handleProductQuantity = (e) => {
    const { product, onHandleProductQuantity } = this.props;
    let maxQuantity = this.getMaxQuantity();

    // Check if package is sold in centimeters.
    maxQuantity = product.countQuantityWithPackageSizeDecimals(maxQuantity);

    const newQuantity = e.target.value
      ? this.getQuantity(Number(e.target.value), maxQuantity)
      : '';

    onHandleProductQuantity(createCartProduct(product.id, newQuantity));

    // Empty string because console throws a warning about controlled inputs that
    // cannot be null.
    // For future improvement, parent component selectedProducts should provide
    // the quantity via prop.
    this.setState({
      quantity: newQuantity,
    });
  };

  getQuantity = (qty, maxQuantity) => {
    const { product } = this.props;
    const freeQuantity = product.getFreeQuantity(product.id);

    const cannotBeOrdered =
      freeQuantity <= 0 && !product.canBeOrderedOutOfStock && qty < 0;
    const canBeOrdered = freeQuantity > 0 && qty > 0 && qty <= maxQuantity;
    const exceedsMaxQuantity = qty >= maxQuantity;
    const nonNegativeQuantity = qty > 0 && qty;

    if (cannotBeOrdered) {
      return 0;
    }

    if (canBeOrdered) {
      return nonNegativeQuantity;
    }

    return exceedsMaxQuantity ? maxQuantity : nonNegativeQuantity;
  };

  getMaxByPackage = (product) =>
    product.sellInPackage
      ? Math.floor(product.getFreeQuantity(product.id) / product.package_size) *
        product.package_size
      : product.getFreeQuantity(product.id);

  getMaxQuantity = () => {
    const { accountStore, configStore, product } = this.props;

    const maxAccountQuantity =
      accountStore.account && accountStore.account.max_product_quantity > 0
        ? accountStore.account.max_product_quantity
        : DEFAULT_MAX_QUANTITY;

    const maxBackorderQuantity =
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
        ? configStore.product.backorderLimit > 0
          ? configStore.product.backorderLimit
          : DEFAULT_MAX_QUANTITY
        : DEFAULT_MAX_QUANTITY;

    const maxNormalProductQuantity =
      product.availability_type !== ProductAvailabilityType.ALLOW_BACKORDER
        ? product.canBeOrderedOutOfStock
          ? DEFAULT_MAX_QUANTITY
          : this.getMaxByPackage(product)
        : DEFAULT_MAX_QUANTITY;

    return Math.min(
      maxAccountQuantity,
      maxBackorderQuantity,
      maxNormalProductQuantity,
      DEFAULT_MAX_QUANTITY
    );
  };

  getUnavailableProduct = () => (
    <div
      key={uniqueId('Cell-')}
      className="ProductMatrixCell__unavailable-product"
    >
      <div className="ProductMatrixCell__line" />
    </div>
  );

  getCellStyle = () => {
    const { uiStore, cellCount } = this.props;
    let width = 100;

    if ((cellCount > 5 && !uiStore.isDesktop) || cellCount > 10) {
      width = 78;
    }

    return {
      width,
    };
  };

  getNoProduct = () => {
    return (
      <div
        key={uniqueId('Cell-')}
        className="ProductMatrixCell__no-product"
        style={this.getCellStyle()}
      >
        <Icon name="ban" />
      </div>
    );
  };

  renderPackageInput = () => {
    const { product, intl } = this.props;
    const { quantity } = this.state;

    const getPackageSizeSum = () => {
      const sum = roundWithPrecision(quantity * product.package_size, 3);

      return (
        <div className="ProductMatrixCell__package-size-sum">
          ( {sum}
          <span className="ProductMatrixCell__package-stock-unit">
            {product.stock_unit}
          </span>
          )
        </div>
      );
    };

    return (
      <div className="ProductMatrixCell__package-input">
        <Input
          id={`ProductInfo-${product.id}`}
          className="ProductMatrixCell__input"
          type="number"
          name={product.id}
          value={quantity}
          onFocus={this.openPopover}
          onBlur={this.closePopover}
          onChange={this.handleProductQuantity}
          innerRef={this.inputRef}
          aria-label={intl.formatMessage(globalTranslations.quantityTitle)}
          placeholder={product.package_unit_name}
          pattern="[0-9]*"
        />
        {getPackageSizeSum()}
      </div>
    );
  };

  renderInput = () => {
    const { product, intl } = this.props;
    const { quantity } = this.state;

    if (product.sellInPackage) {
      return this.renderPackageInput();
    }

    return (
      <Input
        id={`ProductInfo-${product.id}`}
        className="ProductMatrixCell__input"
        type="number"
        name={product.id}
        value={quantity}
        onFocus={this.openPopover}
        onBlur={this.closePopover}
        onChange={this.handleProductQuantity}
        innerRef={this.inputRef}
        aria-label={intl.formatMessage(globalTranslations.quantityTitle)}
        placeholder={product.stock_unit}
      />
    );
  };

  render() {
    const { product, className, onRenderTooltip } = this.props;
    const { popupOpen } = this.state;

    if (!product) {
      return this.getNoProduct();
    }

    if (!product.available_online) {
      return this.getUnavailableProduct();
    }

    return (
      <div
        className={classNames('ProductMatrixCell', className)}
        style={this.getCellStyle()}
      >
        {this.renderInput()}
        {onRenderTooltip(popupOpen)}
      </div>
    );
  }
}

ProductMatrixCell.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  intl: intlShape.isRequired,
  cellCount: PropTypes.number.isRequired,
  onHandleProductQuantity: PropTypes.func.isRequired,
  onRenderTooltip: PropTypes.func.isRequired,
  className: PropTypes.string,
  withPackageUnit: PropTypes.bool,
  withStockUnit: PropTypes.bool,
  product: PropTypes.object,
  propertyListRef: PropTypes.object,
};

export default injectIntl(
  inject('accountStore', 'configStore', 'uiStore')(ProductMatrixCell)
);
