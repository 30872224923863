import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { inject } from 'mobx-react';

import PageTitle from '../../common/PageTitle';
import SEOHomePage from '../../head/SEOHomePage';
import AccountModal from '../AccountModal';
import SetNewPasswordForm from '../SetNewPasswordForm';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import globalTranslations from '../../../i18n/globalTranslations';
import PropTypes from 'prop-types';

const LoginRequired = ({ configStore, intl, token }) => {
  const hasToken = !!token;
  const title =
    configStore.configurationTexts.CONF_SEO_TITLE ||
    intl.formatMessage(globalTranslations.homeTitle);

  return (
    <div className="LoginRequired">
      <SEOHomePage />
      <PageTitle itemProp={title}>{title}</PageTitle>
      {hasToken ? (
        <SetNewPasswordForm passwordResetToken={token} />
      ) : (
        <AccountModal key={'LoginRequired'} useAsModal={false} />
      )}
    </div>
  );
};

LoginRequired.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  intl: intlShape.isRequired,
  token: PropTypes.string.isRequired,
};

export default injectIntl(inject('configStore')(LoginRequired));
